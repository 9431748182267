.elasticity[data-v-e1ae42ae] {
  display: flex;
  align-items: center;
}
.addIcon[data-v-e1ae42ae] {
  margin-left: 20px;
  font-size: 22px;
  cursor: pointer;
}
.aRow[data-v-e1ae42ae] {
  display: flex;
  align-items: center;
}
.el-icon-upload[data-v-e1ae42ae] {
  font-size: 100px;
  color: #dcdfe6;
}
.imgBox[data-v-e1ae42ae] {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.demo-ruleForm[data-v-e1ae42ae] {
  margin-top: 30px;
}
.compile[data-v-e1ae42ae] {
  color: #409eff;
  font-weight: bold;
}
.el-input[data-v-e1ae42ae],
.el-textarea[data-v-e1ae42ae] {
  width: 50%;
}
